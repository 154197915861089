<script>
  import Mixin from '../mixin'
  import MenuAprovacao from './aprovacao/MenuAprovacao'
  import AprovacaoItem from './aprovacao/AprovacaoItem'
  import windowArrematante from '../windows/arrematante'
  import {list} from '@/domain/arrematante/services/arrematante-analise'
  import {UInput} from 'uloc-vue'

  const SEGUNDOS_PARA_RELOAD = 60

  export default {
  name: 'MonitorAprovacaoArrematantes',
  mixins: [Mixin],
  components: {
    AprovacaoItem,
    MenuAprovacao,
    UInput
  },
  data () {
    return {
      loading: false,
      busca: '',
      arrematantes: [],
      secondsRemainingForReloading: SEGUNDOS_PARA_RELOAD,
      intervalId: null
    }
  },
  computed: {
    filteredData () {
      let filterKey = this.busca && this.busca.toLowerCase()
      let data = this.arrematantes
      if (filterKey) {
        data = data.filter(function (row) {
          return Object.keys(row).some(function (key) {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1
          })
        })
      }
      return data
    }
  },
  created () {
    this.load()
  },
  beforeDestroy () {
    this.clearReloadInterval()
  },
  methods: {
    windowArrematante: windowArrematante,
    load () {
      this.loading = true
      list(1000, 1, '')
              .then(response => {
                this.clearReloadInterval()
                console.log(response)
                this.loading = false
                this.arrematantes = response.data.map(item =>{
                  return {
                    id: item.arrematanteId,
                    pessoa: {
                      name: item.name,
                      document: item.document
                    },
                    email: item.email,
                    apelido: item.apelido,
                    image: item.image,
                    analise: {
                      data: item.createdAt,
                      conclusao: null,
                      user: item.active ? {
                        id: item.createdBy,
                        username: item.createdByUser
                      } : null
                    },
                    status: item.status
                  }
                })
                this.initCountdownToReload()
              })
              .catch(error => {
                this.loading = false
                console.log(error)
              })
    },
    initCountdownToReload () {
      this.secondsRemainingForReloading = SEGUNDOS_PARA_RELOAD
      this.intervalId = setInterval(() => {
        if (this.secondsRemainingForReloading === 0) {
          this.load()
        } else {
          --this.secondsRemainingForReloading
        }
      }, 1000)
    },
    clearReloadInterval () {
      if (null !== this.intervalId) clearInterval(this.intervalId)
    }
  },
  meta: {
    title: 'Leiloeiro - Aprovação',
    name: 'Leiloeiro - Aprovação'
  }
}
</script>

<template>
  <div class="flex col-grow no-wrap">
    <menu-aprovacao>
      <u-input placeholder="Buscar por cpf, nome, email..." v-model="busca" />
    </menu-aprovacao>
    <div class="app-layout-cmenu" style="padding-right: 0">
      <transition name="slide" appear>
        <div class="transition-generic">
          <div class="wrapper-lg">
            <div>
              <span class="font-11">Recarregando em {{secondsRemainingForReloading}}</span> <u-btn @click="load" icon="refresh" round rounded flat size="xs" :loading="loading" />
            </div>
            <p class="font-12 text-blue-grey-8">Somente leiloeiros que preencheram os requisitos mínimos para análise
              cadastral estarão disponíveis aqui. </p>
            <div class="aprovacao-container">
              <aprovacao-item @click.native="windowArrematante(arrematante.id)" v-for="(arrematante, index) in filteredData" :key="index" :arrematante="arrematante" />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<style lang="stylus">
  .aprovacao-container {
    margin -10px
    display flex
    flex-wrap: wrap

    .aprovacao-item {
      position relative
      min-width: 260px;
      max-width: 260px;
      min-height: 124px;
      flex: 1;
      background-color: #fff;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      padding: 14px 20px;
      margin: 10px;
      transition: all 0.5s;
      cursor: pointer;

      .user-image {
        position absolute
        width 25px
        height 25px
        left -12px
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        border-radius 500px
      }
    }
  }
</style>

